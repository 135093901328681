.inputSecondary {
	position: relative;
}

.inputSecondary:focus-within .inputLabel {
	visibility: visible;
	opacity: 1;
}

.inputSecondary:focus-within .input {
	padding-bottom: 2px;
}

.input {
	font-size: 16px;
	line-height: 16px;
	height: 56px;
	padding: 17px 16px;
	color: #00171D;
	background: #F7F7F7;
	border: 1px solid transparent;
	border-radius: 2px;
	box-shadow: none !important;
	outline: none;
	transition: border .3s, background .3s;
}

.input:disabled,
.input:disabled:hover {
	background: #EBEBEB !important;
	border: 1px solid #B5B5B5;
}

.input::placeholder {
	color: #999999;
}

.input:focus,
.input:hover {
	background: #fff;
	border: 1px solid #288EA5;
	box-shadow: none !important;
}

.input:focus::placeholder {
	opacity: 0;
}

.inputFocused {
	padding-bottom: 2px;
}

.inputError,
.inputError:focus,
.inputError:hover {
	border-color: #FF5959 !important;
}

.inputLabel {
	font-size: 12px;
	line-height: 12px;
	position: absolute;
	top: 12px;
	left: 17px;
	color: #656565;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
}

.inputLabelVisible {
	visibility: visible;
	opacity: 1;
}

.inputLabelError {
	color: #FF5959 !important;
}

.errorMsgContainer {
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 12px;
}

.errorMessageSecondary {
	font-size: 12px;
	font-weight: 500;
	line-height: 10px;
	color: #FF5959;
}