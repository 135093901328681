.input-secondary .ant-form-item {
  margin: 0;
}
.input-secondary .ant-form-item:focus-within ~ .input-label {
  color: #288EA5;
}
.input-secondary .ant-form-item .ant-input-suffix {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}
.input-secondary .ant-form-item .ant-input-suffix svg {
  width: 20px;
  height: 20px;
}
.input-secondary .ant-input {
  font-size: 16px;
  line-height: 16px;
  color: #00171D;
  background: #F7F7F7;
}
.input-secondary .ant-input::-moz-placeholder {
  color: #999999;
}
.input-secondary .ant-input:-ms-input-placeholder {
  color: #999999;
}
.input-secondary .ant-input::placeholder {
  color: #999999;
}
.input-secondary .ant-input:focus::-moz-placeholder {
  opacity: 0;
}
.input-secondary .ant-input:focus:-ms-input-placeholder {
  opacity: 0;
}
.input-secondary .ant-input:focus::placeholder {
  opacity: 0;
}
.input-secondary .ant-input:focus,
.input-secondary .ant-input-password:focus,
.input-secondary .ant-input:focus-within,
.input-secondary .ant-input-password:focus-within,
.input-secondary .ant-input:hover,
.input-secondary .ant-input-password:hover,
.input-secondary .ant-input:focus .ant-input,
.input-secondary .ant-input-password:focus .ant-input,
.input-secondary .ant-input:focus-within .ant-input,
.input-secondary .ant-input-password:focus-within .ant-input,
.input-secondary .ant-input:hover .ant-input,
.input-secondary .ant-input-password:hover .ant-input {
  background: #fff;
}
.input-secondary .ant-input-affix-wrapper-disabled {
  border: 1px solid #B5B5B5;
}
.input-secondary .ant-input-affix-wrapper-disabled,
.input-secondary .ant-input-affix-wrapper-disabled .ant-input-disabled {
  background: #EBEBEB !important;
}

.InputSecondary_inputSecondary__HCNuG {
	position: relative;
}

.InputSecondary_inputSecondary__HCNuG:focus-within .InputSecondary_inputLabel__Lyz2Z {
	visibility: visible;
	opacity: 1;
}

.InputSecondary_inputSecondary__HCNuG:focus-within .InputSecondary_input___ODxi {
	padding-bottom: 2px;
}

.InputSecondary_input___ODxi {
	font-size: 16px;
	line-height: 16px;
	height: 56px;
	padding: 17px 16px;
	color: #00171D;
	background: #F7F7F7;
	border: 1px solid transparent;
	border-radius: 2px;
	box-shadow: none !important;
	outline: none;
	transition: border .3s, background .3s;
}

.InputSecondary_input___ODxi:disabled,
.InputSecondary_input___ODxi:disabled:hover {
	background: #EBEBEB !important;
	border: 1px solid #B5B5B5;
}

.InputSecondary_input___ODxi::-moz-placeholder {
	color: #999999;
}

.InputSecondary_input___ODxi:-ms-input-placeholder {
	color: #999999;
}

.InputSecondary_input___ODxi::placeholder {
	color: #999999;
}

.InputSecondary_input___ODxi:focus,
.InputSecondary_input___ODxi:hover {
	background: #fff;
	border: 1px solid #288EA5;
	box-shadow: none !important;
}

.InputSecondary_input___ODxi:focus::-moz-placeholder {
	opacity: 0;
}

.InputSecondary_input___ODxi:focus:-ms-input-placeholder {
	opacity: 0;
}

.InputSecondary_input___ODxi:focus::placeholder {
	opacity: 0;
}

.InputSecondary_inputFocused__VnIGP {
	padding-bottom: 2px;
}

.InputSecondary_inputError__35R4I,
.InputSecondary_inputError__35R4I:focus,
.InputSecondary_inputError__35R4I:hover {
	border-color: #FF5959 !important;
}

.InputSecondary_inputLabel__Lyz2Z {
	font-size: 12px;
	line-height: 12px;
	position: absolute;
	top: 12px;
	left: 17px;
	color: #656565;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
}

.InputSecondary_inputLabelVisible__I0qzW {
	visibility: visible;
	opacity: 1;
}

.InputSecondary_inputLabelError__UNMpw {
	color: #FF5959 !important;
}

.InputSecondary_errorMsgContainer__QvvqA {
	display: flex;
	align-items: center;
	grid-gap: 8px;
	gap: 8px;
	margin-top: 12px;
}

.InputSecondary_errorMessageSecondary__aIz02 {
	font-size: 12px;
	font-weight: 500;
	line-height: 10px;
	color: #FF5959;
}
.login_root__BqTCg,
.login_backgroundContainer__IpJw3,
.login_rememberBtnContainer__iqL1x,
.login_orTextContainer__ei0Vz,
.login_googleSignupBtn__BUav3 {
	display: grid;
}

.login_root__BqTCg {
	text-align: center;
	place-items: center;
	height: 100vh;
	background: #F7F7F7;
}

.login_root__BqTCg p {
	margin: 0;
}

.login_loginBtn__oLf7S,
.login_googleSignupBtn__BUav3 {
	width: 100%;
}

.login_loginForm__BHd39 {
	position: relative;
}

.login_loginForm__BHd39,
.login_loginBtn__oLf7S,
.login_googleSignupBtn__BUav3 {
	border-radius: 2px;
}

.login_loginForm__BHd39 {
	width: 462px;
	padding: 60px 56px;
	background: #fff;
	box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04);
}

.login_logo__yiYxh {
	margin-bottom: 60px;
}

.login_errorMessage__lP6HX,
.login_forgotPassword__qaLql,
.login_signupLink__nwQrG {
	font-weight: 500;
}

.login_errorMessage__lP6HX {
	text-align: left;
	margin-bottom: 24px;
	color: #FF5959;
}

.login_errorMessage__lP6HX,
.login_rememberText__sqtf9
.login_forgotPassword__qaLql {
	font-size: 14px;
	line-height: 14px;
}

.login_inputsContainer___2Z_J {
	display: grid;
	grid-gap: 12px;
	gap: 12px;
}

.login_rememberBtnContainer__iqL1x {
	grid-template-columns: repeat(2, -webkit-max-content) 1fr;
	grid-template-columns: repeat(2, max-content) 1fr;
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
	margin: 24px 0;
	line-height: 14px;
}

.login_rememberText__sqtf9 {
	color: #052A33;
}

.login_forgotPassword__qaLql {
	color: #288EA5;
	justify-self: end;
}

.login_loginBtn__oLf7S {
	font-size: 16px;
	font-weight: 600;
	line-height: 32px;
	height: auto;
	padding: 7px;
	margin-bottom: 16px;
	background: #288EA5;
}

.login_signupLinkContainer__yWAlB {
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 24px;
	color: #656565;
}

.login_orTextContainer__ei0Vz {
	font-size: 12px;
	line-height: 15px;
	color: #656565;
	grid-template-columns: repeat(3, -webkit-min-content);
	grid-template-columns: repeat(3, min-content);
	align-items: center;
	justify-content: center;
	grid-gap: 16px;
	gap: 16px;
	margin-bottom: 12px;
}

.login_line__ajvt9 {
	width: 100px;
	height: 1px;
}

.login_lineLeft__VX60m {
	background: linear-gradient(270deg, #E5E5E5 0%, rgba(238, 238, 238, 0) 100%);
}

.login_lineRight__JVxvy {
	background: linear-gradient(270deg, rgba(238, 238, 238, 0) 0%, #E5E5E5 100%);
}

.login_googleSignupBtn__BUav3 {
	font-size: 16px;
	font-weight: 600;
	line-height: 19px;
	color: #052A33;
	height: auto;
	grid-template-columns: repeat(3, -webkit-min-content);
	grid-template-columns: repeat(3, min-content);
	align-items: center;
	justify-content: center;
	grid-gap: 16px;
	gap: 16px;
	padding: 12px;
	border: 1px solid #EBEBEB;
}

.login_buttonIcon__s8fas {
    height: 24px;
}
